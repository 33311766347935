<template>
  <!--begin::Toolbar-->
  <div class="toolbar" id="kt_toolbar" style="margin-top: 2.65rem !important">
    <!--begin::Container-->
    <div
      id="kt_toolbar_container"
      :class="{
        'container-fluid': toolbarWidthFluid,
        'container-xxl': !toolbarWidthFluid,
      }"
      class=""
    >
      <!--begin::Page title-->
      <div
        data-kt-swapper="true"
        data-kt-swapper-mode="prepend"
        data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
        class="page-title align-items-center me-3 mb-5 mb-lg-0"
      >
        <div class="row">
          <div class="col-8">
            <h1 class="align-items-center fw-bolder my-2 fs-3 page-title">
              {{ title }}
            </h1>
          </div>

          <div class="col-4 pe-2">
            <span>
              <h1
                v-if="routeIncludesQueryString"
                class="mb-0 badge text-uppercase"
                :class="
                  invitationStatus === 'Confermato'
                    ? 'badge-light-success'
                    : invitationStatus === 'nuovo'
                    ? 'badge-light-primary'
                    : invitationStatus === 'Rifiutato'
                    ? 'badge-light-danger'
                    : invitationStatus === 'Inviato'
                    ? 'badge-light-primary'
                    : invitationStatus === 'Da confermare'
                    ? 'badge-light-accettato'
                    : invitationStatus === 'bozza'
                    ? 'badge-light-warning'
                    : invitationStatus === 'consiglio federale'
                    ? 'badge-light-federali'
                    : ''
                "
              >
                {{ invitationStatus != "{}" ? invitationStatus : "" }}
              </h1>
            </span>
          </div>
        </div>

        <!--end::Page title-->
      </div>
      <!--end::Container-->
    </div>
  </div>
  <!--end::Toolbar-->
</template>

<script>
import { defineComponent } from "vue";
/* import Dropdown1 from "@/components/dropdown/Dropdown1.vue"; */
import { toolbarWidthFluid } from "@/core/helpers/config";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "KToolbar",
  props: {
    breadcrumbs: Array,
    invitationStatus: String,
    title: String,
    path: String,
  },
  components: {
    /*    Dropdown1, */
  },
  setup() {
    const route = useRoute();
    return {
      route,
      toolbarWidthFluid,
    };
  },
  computed: {
    routeIncludesQueryString() {
      return this.route.query.i && this.route.query.p;
    },
  },
});
</script>

<style>
@media (min-width: 992px) {
  .toolbar-fixed .toolbar {
    /* position: fixed; */
    position: inherit;
    border-top: 1px solid #eff2f5;
    top: 0;
    right: 0;
    left: 0;
    background-color: #fff;
    box-shadow: 0px 10px 30px 0px rgb(82 63 105 / 5%);
    z-index: 100px;
  }
}

@media (min-width: 992px) {
  .content {
    padding: 0 0;
  }
}

@media (max-width: 992px) {
  .toolbar-fixed .toolbar {
    display: none;
  }
}
</style>
